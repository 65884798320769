import Image from '@/components/base/image';
import { ISliderMedia } from './types';
import { useApp, path } from '@wap-client/core';
import SocialIconList from '../social-icon-list';
import Anchor from '@/components/base/anchor';
import Icon from '@/components/base/icon';
import { useEffect, useRef, useState } from 'react';
import useDeviceType from '@/hooks/useDeviceType';

const SliderHeroItem: React.FC<
  ISliderMedia & {
    index: number;
    sliderMediaLength: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    swiperRef: React.RefObject<any>;
  }
> = ({
  image,
  mobileImage,
  video,
  videoMobile,
  desc,
  mainDesc,
  mainTitle,
  title,
  titleIcon,
  index,
  sliderMediaLength,
  activeIndex,
  setActiveIndex,
  emergencyLink,
  isEmergency,
  swiperRef,
}) => {
  const app = useApp();
  const check = title === null && desc === null;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(true);
  const deviceType = useDeviceType();

  const mobileImageUrl = path.asset(app.environment, mobileImage?.src) || '';
  const desktopImageUrl = path.asset(app.environment, image?.src) || '';

  let videoUrl = deviceType === 'mobile' ? videoMobile?.src : video?.src;

  useEffect(() => {
    if (videoMobile || video) {
    }
  }, [deviceType]);

  useEffect(() => {
    if (index !== activeIndex && videoIsPlaying) {
      handleVideoPause();
    } else if (index === activeIndex) {
      handleVideoPlay();
    }
  }, [activeIndex, index]);

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      swiperRef.current.swiper.autoplay.stop();
      setVideoIsPlaying(true);
    }
  };

  const handleVideoPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setVideoIsPlaying(false);
    }
  };

  const handleVideoEnded = () => {
    setVideoIsPlaying(false);
    if (videoRef.current) {
      swiperRef.current.swiper.autoplay.start();
      swiperRef.current.swiper.slideNext();
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  };

  return (
    <div className={`slider-hero-item ${check ? 'no-text' : 'has-text'}`}>
      <div className="slider-hero-item-media">
        {image && (
          <picture className="image-container">
            <source media="(min-width:700px)" srcSet={desktopImageUrl} />
            <img
              fetchPriority="high"
              src={mobileImageUrl}
              alt={` image`}
              loading="lazy"
            />
          </picture>
        )}

        {video?.src && (
          <video
            ref={videoRef}
            onEnded={handleVideoEnded}
            poster={path.asset(app.environment, video.poster)}
            className="slider-hero-item-media-video"
            controls={false}
            playsInline
            muted
          >
            <source
              src={path.asset(app.environment, videoUrl)}
              type="video/mp4"
            />
          </video>
        )}

        {video?.src && (
          <button
            onClick={videoIsPlaying ? handleVideoPause : handleVideoPlay}
            className="video-handle-btn"
          >
            <Icon name={videoIsPlaying ? 'icon-pause' : 'icon-play'} />
          </button>
        )}

        {(mainTitle || mainDesc) && (
          <div className="main-media-texts">
            {mainTitle && <h1 className="title">{mainTitle}</h1>}
            {mainDesc && <p className="desc">{mainDesc}</p>}
            {titleIcon && (
              <Image
                alt={`${mainTitle} image`}
                className="title-icon"
                {...titleIcon}
              />
            )}
          </div>
        )}

        <div className="custom-pagination">
          {Array.from({ length: sliderMediaLength }).map((_, index) => (
            <span
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`pagination-dot ${
                index === activeIndex ? 'active' : ''
              }`}
            />
          ))}
        </div>

        <SocialIconList
          className={`slider-item-media-socials ${
            video?.src ? 'slider-item-media-socials-right' : ''
          }`}
        />

        {isEmergency && emergencyLink?.href && (
          <div className="emergency">
            <Anchor className="emergency-btn" {...emergencyLink}>
              <Icon name="icon-emergency" />
              <span className="text">
                {app.settings.translations['emergency']}
              </span>
            </Anchor>
          </div>
        )}
      </div>

      {(title || desc) && (
        <div className="sub-media-texts">
          {title && <div className="title">{title}</div>}
          {desc && <div className="desc">{desc}</div>}
        </div>
      )}
    </div>
  );
};

export default SliderHeroItem;
