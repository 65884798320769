import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import { ISliderHeroProps } from './types';
import { Container } from '@/components/base/gridview';
import SingleSliderHero from '../single-slider-hero';
import SliderHeroItem from './SliderHeroItem';

import 'swiper/css';
import 'swiper/css/pagination';

const Hero: React.FC<ISliderHeroProps> = ({
  sliderMedia = [],
  isEmergency = false,
  emergencyLink,
}) => {
  const swiperRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (swiperRef) {
      swiperRef &&
        swiperRef?.current &&
        swiperRef?.current?.swiper?.slideTo(activeIndex);
    }
  }, [activeIndex]);

  return (
    <section className="hero">
      <Container size="fluid">
        {sliderMedia.length > 1 ? (
          <Swiper
            ref={swiperRef}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            initialSlide={activeIndex}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          >
            {sliderMedia.map((slider, index) => (
              <SwiperSlide key={index}>
                <SliderHeroItem
                  {...slider}
                  index={index}
                  sliderMediaLength={sliderMedia.length}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  emergencyLink={emergencyLink}
                  isEmergency={isEmergency}
                  swiperRef={swiperRef}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <SingleSliderHero
            media={sliderMedia[0]}
            isEmergency={isEmergency}
            emergencyLink={emergencyLink}
          />
        )}
      </Container>
    </section>
  );
};

export default Hero;
