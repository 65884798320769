import React from 'react';
import { ISocialIconListProps } from './types';
import { functions, useApp } from '@wap-client/core';
import Anchor from '@/components/base/anchor/Anchor';
import Icon from '@/components/base/icon';
const SocialIconList: React.FC<ISocialIconListProps> = ({ className }) => {
  const app = useApp();
  return (
    <div className={functions.classnames('social-icon-list', className)}>
      <Anchor
        href={app.settings.socials['instagram']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-instagram" />
      </Anchor>
      <Anchor
        href={app.settings.socials['youtube']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-youtube-full" />
      </Anchor>
      <Anchor
        href={app.settings.socials['twitter']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-twitter" />
      </Anchor>
      <Anchor
        href={app.settings.socials['facebook']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-facebook-thin" />
      </Anchor>
      <Anchor
        href={app.settings.socials['pinterest']}
        className="list-item"
        target="_blank"
      >
        <Icon name="icon-tik-tok" />
      </Anchor>
    </div>
  );
};

export default SocialIconList;
